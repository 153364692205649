import React, { useState } from 'react'
// import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CardBody, Card, CardHeader, Input, Table } from 'reactstrap'

const UserFilter = ({ isMSBAdmin, fetchUsers }) => {
  const USER_FILTERS = [
    { key: 'User.UserID', name: 'ID', operators: ['LIKE', 'EXACT', 'LESS_THAN', 'GREATER_THAN'] },
    { key: 'User.userName', name: 'Name', operators: ['LIKE', 'EXACT'] },
    { key: 'User.userCompany', name: 'Company', operators: ['LIKE', 'EXACT'] },
    { key: 'User.userUID', name: 'UID', operators: ['LIKE', 'EXACT'] },
    { key: 'User.userPhone', name: 'Phone', operators: ['LIKE', 'EXACT'] },
    { key: 'User.userExtraPhone', name: 'Extra Phone', operators: ['LIKE', 'EXACT'] },
    { key: 'User.userEmail', name: 'Email', operators: ['LIKE', 'EXACT'] },
    {
      key: 'User.userAccess',
      name: 'Access',
      operators: ['EXACT'],
      options: ['APPROVED', 'LIMITED', 'EMPTY', 'WAITING', 'PAUSED', 'DECLINED', 'REMOVED']
    }
    // { key: 'userPointsComputed', name: 'Point', operators: ['EXACT', 'LESS_THAN', 'GREATER_THAN']}
  ]
  if (isMSBAdmin) {
    USER_FILTERS.push({
      key: 'User.userRole',
      name: 'Role',
      operators: ['EXACT'],
      options: ['System', 'Agent', 'Company', 'Client', 'Brand', 'User']
    })
    USER_FILTERS.push({ key: 'User.userPrivilege', name: 'Privelege', operators: ['EXACT'], options: ['MANAGE', 'VIEW', 'EDIT', 'OWNER'] })
    USER_FILTERS.push({ key: 'User.BrandID', name: 'Brand ID', operators: ['EXACT', 'LESS_THAN', 'GREATER_THAN'] })
    USER_FILTERS.push({ key: 'Brand.brandName', name: 'Brand Name', operators: ['LIKE', 'EXACT'] })
  }
  // if(enableWalletActivation || isMSBAdmin){
  //   USER_FILTERS.push({ key: 'userPointsWaiting', name: 'Waiting Point', operators: ['EXACT', 'LESS_THAN', 'GREATER_THAN'] })
  // }

  const [userFilters, setUserFilters] = useState([{ column: 'User.UserID', value: '', operator: 'EXACT' }])

  const updateUserFilter = (fieldName, index, value) => {
    setUserFilters(
      userFilters.map((userFilter, idx) => {
        if (index === idx) {
          userFilter[fieldName] = value
          if (fieldName === 'column') {
            const filter = USER_FILTERS.find(fl => fl.key === value)
            if (filter) {
              userFilter.operator = filter.operators[0]
            }
          }
        }
        return userFilter
      })
    )
  }

  const removeUserFilter = column => {
    setUserFilters(userFilters.filter(fl => fl.column !== column))
  }

  const addUserFilter = () => {
    setUserFilters([...userFilters, { column: 'User.UserID', value: '', operator: 'EXACT' }])
  }

  const applyFilters = () => {
    const finalFilters = userFilters.filter(fl => !!fl.value)
    fetchUsers({ filter: finalFilters })
  }

  return (
    <React.Fragment>
      <Card>
        <CardHeader tag='h4'>
          <div style={{ marginLeft: 32, marginRight: 32, display: 'flex', justifyContent: 'space-between' }}>
            <span>Filtre</span>
            <Button onClick={addUserFilter}>
              <FontAwesomeIcon icon='plus' />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Table responsive size='sm'>
            <thead>
              <tr>
                <th>Kilde</th>
                <th>Operatør</th>
                <th>Værdi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userFilters.map((userFilter, index) => {
                const currentFilter = USER_FILTERS.find(fl => fl.key === userFilter.column)
                const operators = currentFilter ? currentFilter.operators : ['LIKE']
                const options = currentFilter && currentFilter.options ? currentFilter.options : null
                return (
                  <tr key={index}>
                    <td>
                      <Input
                        type='select'
                        onChange={t => {
                          updateUserFilter('column', index, t.target.value)
                        }}
                        value={userFilter.column}
                        name='column'
                      >
                        <option value='' disabled>
                          Vælg
                        </option>
                        {USER_FILTERS.map((filter, idx) => {
                          return (
                            <option key={idx} value={filter.key}>
                              {filter.name}
                            </option>
                          )
                        })}
                      </Input>
                    </td>
                    <td>
                      <Input
                        type='select'
                        onChange={t => {
                          updateUserFilter('operator', index, t.target.value)
                        }}
                        value={userFilter.operator}
                        name='operator'
                      >
                        {operators.map((oprtr, idx) => {
                          return (
                            <option key={idx} value={oprtr}>
                              {oprtr}
                            </option>
                          )
                        })}
                      </Input>
                    </td>
                    <td>
                      <Input
                        type={options ? 'select' : 'text'}
                        onChange={t => {
                          updateUserFilter('value', index, t.target.value)
                        }}
                        value={userFilter.value}
                        name='value'
                      >
                        {options &&
                          options.map((option, idx) => {
                            return (
                              <option key={idx} value={option}>
                                {option}
                              </option>
                            )
                          })}
                      </Input>
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          removeUserFilter(userFilter.column)
                        }}
                        color='danger'
                      >
                        <FontAwesomeIcon icon='minus' />
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <React.Fragment>{userFilters.length > 0 && <Button onClick={applyFilters}>Filtrer</Button>}</React.Fragment>
          <React.Fragment>
            {userFilters.length > 0 && (
              <Button
                color='danger'
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  setUserFilters([])
                  applyFilters()
                }}
              >
                Clear Filtrer
              </Button>
            )}
          </React.Fragment>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default UserFilter
